import { useEffect, useState, useRef } from "react";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import './PaypalDonate.css'
import Donate from "./Donate";
import { makePostRequest } from "../services/httpHelper";
import ErrorHandler from "../services/ErrorHandler";
import { Link } from "react-router-dom";

// There are 3 ways to keep the amount from being stale in our createOrder callback:
// 1. Use amountRef.current to keep track of amount changes
// 2. Use the forceReRender prop (which causes flashing)
// 3. Use the key property (which also causes flashing)

const DonateButton = ({currency, amount,donorName,phone,donationType,email, country,address,anon,notes,church }) => {

  const amountRef = useRef(amount);

  
  useEffect(() => {
    amountRef.current = amount;
  }, [amount]);

  return (
    <PayPalButtons
      // forceReRender={[currency, amount]}
      style={{ color: "black", label: "donate" }}
      fundingSource="paypal"
      createOrder={(data, actions) => {
        return actions.order.create({
          purchase_units: [
            {
              amount: {
                value: amountRef.current,
                breakdown: {
                  item_total: {
                    currency_code: currency,
                    value: amountRef.current
                  }
                }
              },
              items: [
                {
                  name: church.church_name,
                  description:
                    notes,
                  quantity: "1",
                  unit_amount: {
                    currency_code: currency,
                    value: amountRef.current
                  },
                  category: "DONATION"
                }
              ]
            }
          ]
        });
      }}
    />
  );
};

function DonateForm() {
  const [amount, setAmount] = useState("5.00");
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState(null);
  const [country, setCountry] = useState(null);
  const [address, setAddress] = useState(null);
  const [dType, setDType] = useState('One-Time');
  const [receipt, setReceipt] = useState(false);
  const [anon, setAnon] = useState(false);
  const [list, setList] = useState(false);
  const [notes, setNotes] = useState(null);
  const[error,setError]=useState(null);
  const[isSuccess,setIsSuccess]=useState(false);
  const[isLoading,setIsLoading]=useState(false);
  const[isSubmitting,setIsSubmitting]=useState(false);

  const user = JSON.parse(localStorage.getItem('user'));
  const church = JSON.parse(localStorage.getItem('church'));

  // Handle change in input fields
  const handleInputChange = (e, setter) => {
    setter(e.target.value);
  };

  // Handle change in radio buttons
  const handleRadioChange = (value) => {
    setDType(value);
    alert(dType)
  };

  // Handle change in checkbox inputs
  const handleCheckboxChange = (e, setter) => {
    setter(e.target.checked);
  };

  // Handle click on donation amount buttons
  const handleAmountClick = (value) => {
    setAmount(value);
  };

  //save details in the database
  async function createDonorProfile(e){
    console.log('called..')
    e.preventDefault();
        setError(null)
        setIsSubmitting(true)
        setIsSuccess(false)
        
        const data ={
            donor_name:firstName+' '+lastName ,
            email:email,         
            phone:phone,
            country:country,
            address:address,           
            church_id:church.id,
            notes:notes,
            anon:anon

        }

        const res = await makePostRequest('donor',data);
        if (res && res.errorType) {
            setIsSubmitting(false);
            setIsSuccess(false)
            const errorMessage = ErrorHandler(res)
            setError(errorMessage)

        } else {
          
         
            setIsSubmitting(false);
            setError(null);
            setIsSuccess(true)
            

        }
  }

  return (
    <div>
<div className="donation-container">
      <div className="donation-box">
        <div className="title">Donation Information</div>
        <div className="fields">
          <input
            type="text"
            id="firstName"
            placeholder="First Name"
            value={firstName}
            onChange={(e) => handleInputChange(e, setFirstName)}
          />
          <input
            type="text"
            id="lastName"
            placeholder="Last Name"
            value={lastName}
            onChange={(e) => handleInputChange(e, setLastName)}
          />
          <input
            type="text"
            id="email"
            placeholder="Email"
            value={email}
            onChange={(e) => handleInputChange(e, setEmail)}
          />

          <input
            type="text"
            id="phone"
            placeholder="Ohone Number"
            value={phone}
            onChange={(e) => handleInputChange(e, setPhone)}
          />
            <input
            type="text"
            id="country"
            placeholder="Country"
            value={country}
            onChange={(e) => handleInputChange(e, setCountry)}
          />

          <input
            type="text"
            id="address"
            placeholder="Address"
            value={address}
            onChange={(e) => handleInputChange(e, setAddress)}
          />
            <input
            type="notes"
            id="noted"
            placeholder="Notes"
            value={notes}
            onChange={(e) => handleInputChange(e, setNotes)}
          />
        </div>
        <div className="amount">
          <div className={`button ${amount === '$30' && 'selected'}`} onClick={() => handleAmountClick('$30')}>$30</div>
          <div className={`button ${amount === '$50' && 'selected'}`} onClick={() => handleAmountClick('$50')}>$50</div>
          <div className={`button ${amount === '$100' && 'selected'}`} onClick={() => handleAmountClick('$100')}>$100</div>
          <div className="button">$<input
            type="text"
            className="set-amount"
            placeholder=""
            value={amount}
            onChange={(e) => handleInputChange(e, setAmount)}
          /></div>
        </div>
        <div className="switch" style={{position:'relative',top:'98%',left:'-14%'}}>
          <input
            type="checkbox"
            className="switch-input"
            name="view"
            value="One-Time"
            id="one-time"
            checked={dType === 'One-Time'}
            onChange={() => handleRadioChange('One-Time')}
          />
          <label htmlFor="one-time" className="switch-label switch-label-off">One-Time</label>
          <input
            type="checkbox"
            className="switch-input"
            name="view"
            value="Monthly"
            id="monthly"
            checked={dType === 'Monthly'}
            onChange={() => handleRadioChange('Monthly')}
          />
          <label htmlFor="monthly" className="switch-label switch-label-on">Monthly</label>
          <span className="switch-selection"></span>
        </div>
        <div className="checkboxes" style={{position:'relative',top:'105%',left:'-1%'}}>
          <input
            type="checkbox"
            id="receipt"
            className="checkbox"
            checked={receipt}
            onChange={(e) => handleCheckboxChange(e, setReceipt)}
          />
          <label htmlFor="receipt">Email Me A Receipt</label>
          <br />
          <input
            type="checkbox"
            id="anon"
            className="checkbox"
            checked={anon}
            onChange={(e) => handleCheckboxChange(e, setAnon)}
          />
          <label htmlFor="anon">Give Anonymously</label>
          <br />
          <input
            type="checkbox"
            id="list"
            className="checkbox"
            checked={list}
            onChange={(e) => handleCheckboxChange(e, setList)}
          />
          <label htmlFor="list">Add Me To Email List</label>
          <br/>

          {
        !isSuccess &&  <button className="donate-button"><i className="fa fa-credit-card" onClick={createDonorProfile}></i> Continue </button>
       }
        </div>
        <div className="confirm">
          {/* Confirmation message can be displayed here */}
        </div>
    
      </div>

      
    </div>

   {
    isSuccess &&  <DonateButton currency="USD" amount={amount} donorName={firstName+' '+lastName} email={email} phone={phone} country={country} address={address} donationType={dType} anon={anon} church={church}/>
   }

{/*<form className="DonateForm">
      <AmountPicker
        onAmountChange={(e) => {
          setAmount(e.target.value);
        }}
      />
      <DonateButton currency="USD" amount={amount} />
    </form>*/}

    </div>
 
  );
}

function AmountPicker({ onAmountChange }) {
  <Donate/>
  return (
    <fieldset onChange={onAmountChange}>
      <legend>Donation Amount</legend>
      <label>
        <input type="radio" value="5.00" defaultChecked="true" name="amount" />
        5.00
      </label>
      <label>
        <input type="radio" value="10.00" name="amount" id="radio-6" />
        10.00
      </label>
      <label>
        <input type="radio" value="15.00" name="amount" id="radio-9" />
        15.00
      </label>
    </fieldset>
  );
}

export function DonateApp() {
    const church = JSON.parse(localStorage.getItem('church'))
  return (
    <PayPalScriptProvider
      options={{
        "client-id": "AYTWdX7tpg0mSjeGsQZrc_QCesXFaQzJZeTZfMGxhGtVVCvEYK8aDzPhn32RYWvmOE4L5Ikg6Wjq-LUR",
        components: "buttons",
        currency: "USD"
      }}
    >
      <h1>Donate to {church.church_name.toUpperCase()}</h1>
      <figure>
       {/* 
        <img src="https://placekitten.com/500/300" alt="Kitty Looking Cute" />
       */}
        <figcaption></figcaption>
      </figure>
      <DonateForm />
    </PayPalScriptProvider>
  );
}
