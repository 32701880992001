import React,{useState,useEffect} from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import MissionImage from '../assets/images/mission.jpg';
import VisionImage from '../assets/images/vision.jpg';
import HistoryImage from '../assets/images/history.jpg';
import './About.css'
import Leadership from '../components/Leadership';
import { Helmet } from "react-helmet-async";




const About =()=>{
  const church = JSON.parse(localStorage.getItem('church'));

  
  

    return(
      <>

<Helmet>
  <title>About Us</title>
  <meta name="description" content={church.mission}/>
   <link rel='canonical' href='/about'/>


</Helmet>

<div className='wrapper '>
          <section id="about" className="about">
          <Container className='about'>
  <h1 className='text-center'>ABOUT US</h1>
      <Row>
        <Col lg={6} md={12}>
          <img src={MissionImage} alt="Mission" className="img-fluid"/>
        </Col>
        <Col lg={6} md={12} className="d-flex align-items-center">
          <div>
            <h2>Mission</h2>
            <p>{church.mission}</p>
          </div>
        </Col>
      </Row>

      <Row className="flex-lg-row-reverse">
        <Col lg={6} md={12}>
          <img src={VisionImage} alt="Vision" className="img-fluid" />
        </Col>
        <Col lg={6} md={12} className="d-flex align-items-center">
          <div>
            <h2>Vision</h2>
            <p>{church.vision}</p>
          </div>
        </Col>
      </Row>

      <Row>
        <Col lg={6} md={12}>
          <img src={HistoryImage} alt="History" className="img-fluid" />
        </Col>
        <Col lg={6} md={6} className="d-flex align-items-center">
          <div>
            <h2>Our History</h2>
            <p>{church.history}</p>
          </div>
        </Col>
        
      </Row>

  


      <h2 className="mt-5">Our Leadership</h2>
     <Leadership/>
    </Container>
            
            </section> 






        </div>
      
      </>


        )}
    
const LeaderCard = ({ image, name, rank }) => {
  return (
    <Card className="mb-4">
      <Card.Img variant="top" src={image} alt={name} />
      <Card.Body>
        <Card.Title>{name}</Card.Title>
        <Card.Text>{rank}</Card.Text>
      </Card.Body>
    </Card>
  );
};


export default About;