import React from 'react';
import Hero from '../components/Hero';
import Mission from '../components/Mission';
import ContactForm from '../components/ContactForm';
import Buttons from '../components/Buttons';
//import UpcomingEvents from '../components/UpcomingEvents';
import GetInvolved from '../components/GetInvolved';
import Events from '../components/Events';

import FAQs from '../components/FAQs';
import ActionSection from '../components/ActionSection';
import WeeklyProgram from '../components/WeeklyProgram';
import Testimonials from '../components/Testimonials';
import PhotoGallery from '../components/PhotoGallery';
import HorizontalVideoGallery from '../components/HorizontalVideoGallery';






const Home = () => {

  
 
  return (
   <>
    
    <Hero/>

    <section>
    <ActionSection/>

    </section>
   
    <Mission id='mission'/>
    <section id="events">
      <h3> Events</h3>
  
      <Events/>
    </section>

    <section id='get-involved'>
      <h3>Get Involved</h3>
      <GetInvolved/>
    </section>
 
    <section id='weekly-program'>
      <Testimonials/>
    </section>

    <section id='faqs' style={{marginTop:'35px'}}>
      <br/>
      <h3>Frequently Asked Questions</h3>
      <FAQs/>
    </section>
    <section id='photo-gallery'>
    <h3>Photo Gallery</h3>
      <PhotoGallery/>
    </section>

     <section id='h-video-gallery'>
    <h3>Video Gallery</h3>
      <HorizontalVideoGallery/>
     
    </section>
  
   <ContactForm id='contact'/>

   </>
  );
};

export default Home;
