import React from 'react';
import { useParams } from 'react-router-dom';

const MessageSendSuccess =()=>{
    const {name} = useParams();
    return(
        <div className="container-fluid">
            <h2>Message Send</h2>
            <p className="text txt-success">Thank you <strong>{name.toUpperCase()}</strong> for contacting us. We will be back to you as soon as possible. Be blessed.</p>
        </div>
    )
}

export default MessageSendSuccess;