import React from 'react';
import './ItemCard.css'



const ItemCard =({title,text,img})=>{
  return (
   
    <div className="main-bx" id="mnbxe">
    <div className="secn-bx">
      <div>
        <img id="img-bx" src={img} />
      </div>


      <div className="headr-name">{title}</div>

      <p className="hdr-pr">
        {text}...
      </p>

      <div className="btncn" id="anim">
        <div className="tooltip-container" onmouseenter="showTooltip()" onmouseleave="hideTooltip()">
     
        
        </div>
      </div>
    </div>
  </div>
  )
  

}

export default ItemCard;

