import React, { useEffect, useState } from 'react';
import { makeGetRequest } from '../services/httpHelper';
import { Link } from 'react-router-dom';
import './Events.css';
import { BASE_URL, IMAGE_BASE_URL } from '../constants/paths';



const EventDetail = ({event}) => {
  const [events, setEvents] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);

  const church = JSON.parse(localStorage.getItem('church'));

  useEffect(() => {
    // Fetch events from the backend when the component mounts
    async function fetchEvents() {
      try {
        const response = await makeGetRequest(`/church-events/${church.id}`); // Adjust the API 
        setEvents(response.data);
      } catch (error) {
        console.error('Error fetching events:', error);
      }
    }

    fetchEvents();
  }, []);

  return (
    <>
     
    

<div className="container py-5">
<h2>{event.event_title}</h2>
<h5> From {event.start_date} {event.start_time} to {event.end_date} {event.end_time} in {event.location}</h5>
<h3>Required Fee: {event.fee == 0 ? 'ITS FREE':event.fee}</h3>

    <div className="image-container" onClick={handleShow}>
        <div className="bottom-left">
          <div className="thumbnail" style={{backgroundColor: "#008080"}}>
            <div className="thumbnail-date">
              <span className="thumbnail-date-day">{new Date(event.start_date).getDay()}</span>
              <span className="thumbnail-date-month">{new Date(event.start_date).getUTCMonth()}</span>
            </div>
          </div>
        </div>
        <Link >
        <img src={IMAGE_BASE_URL+event.attachment}  className="card-img-top" alt="event photo" onClick={handleShow}/>
              
          </Link>
        <div className="image-overlay text-over text-center d-flex justify-content-center align-items-center text-uppercase">
          <div>
            {event.event_title}
          </div>
        </div>
     
      </div>
      <p style={{fontSize:"24px"}}>{event.description}</p>

   




</div>


    </>
    
  );
};

export default EventDetail;
