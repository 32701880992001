import React,{useState,useEffect} from 'react';
import './PhotoGallery.css'; // Import CSS file for styling
import { makeGetRequest } from '../services/httpHelper';
import ErrorHandler from '../services/ErrorHandler';
import { IMAGE_BASE_URL } from '../constants/paths';


const PhotoGallery = () => {
    const [photos, setPhotos] = useState([]);
    const [isLoading,setIsLoading] = useState(false);
    const [error,setError] = useState(null);

    const church = JSON.parse(localStorage.getItem('church'))

  

  const getPhotos = async()=>{
    setIsLoading(true);
    const res = await makeGetRequest(`photo-galleries/${church.id}`);
    if (res && res.errorType) {
        setIsLoading(false);
        const errorMessage = ErrorHandler(res)
        setError(errorMessage)

    } else {
      const result = await res.data
        setPhotos(result)        
        setIsLoading(false);
        setError(null)
        console.log(photos)


    }
   
}
  

  useEffect(()=>{
    getPhotos();

  },[])

  return (
    <div className='gallery-container'>
        <div className="parent-container">

        {
            photos.length !== 0 ?
            photos.map((photo)=>{
                return  <div className="child-container" key={photo.id}>
                    <img  src={IMAGE_BASE_URL+photo.photo} width="100%" height="auto" alt={photo.caption}/>
       
              
              </div>
                
            })

            :
            <p>Photo gallery is empty</p>
        }
     {/* <div className="child-container" id="one">
       
        {images.slice(0, 5).map((image, index) => (
          <img key={index} src={image} width="100%" height="auto" alt={`Image ${index + 1}`} />
        ))}
      </div>
      <div className="child-container" id="two">
        {images.slice(5, 8).map((image, index) => (
          <img key={index} src={image} width="100%" height="auto" alt={`Image ${index + 6}`} />
        ))}
      </div>
      <div className="child-container" id="three">
        {images.slice(8).map((image, index) => (
          <img key={index} src={image} width="100%" height="auto" alt={`Image ${index + 9}`} />
        ))}
      </div>
      <div className="greeting">
        <h1>Thanks for visiting</h1>
        <a href="https://codepen.io/Mysha484" target="_blank" rel="noopener noreferrer"><i className="fab fa-codepen"></i></a>
      </div>*/}
    </div>

    </div>
    
  );
};

export default PhotoGallery;
