import React from 'react';
import { Link } from 'react-router-dom';
import './Mission.css'
import { FaLowVision } from 'react-icons/fa';
import { Container, Row, Col, Card } from 'react-bootstrap';

const Mission = () => {
  const church = JSON.parse(localStorage.getItem('church'));

  return (
   <>
   <div className="mission-container">
        <h3><FaLowVision/> &nbsp;Community Engagements</h3>
        <h4>As Church that is focused on spreading the true Gospel of our Lord Jesus Christ, we equally touch lives through our strategic community engagements. </h4>

<div className='container text-center'>
<div className="container text-center">
            <Link  to={`/${church.website}/community-engagements`} style={{position:'relative', zIndex:'2',color:'#fff',fontSize:'30px'}}>
            <button type="button" className="btn btn-outline" style={{marginLeft:"50%"}}>Find out More..</button>
            </Link>

        </div>
</div>
    </div>
   </>
  );
};

export default Mission;
