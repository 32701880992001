import React, { useState,useEffect } from 'react';
import { makeGetRequest } from '../services/httpHelper';
import ErrorHandler from '../services/ErrorHandler';

const FaithStatements = ()=>{

    const [statements, setStatements] = useState([])
  const [isLoading,setIsLoading] = useState(false);
  const [error,setError] = useState(null);
  const[isSubmitting, setIsSubmitting]=useState(false)


 
const church = JSON.parse(localStorage.getItem('church'))

const getStatements = async()=>{
  setIsLoading(true);
  const res = await makeGetRequest(`faith-statements/${church.id}`);

  if (res && res.errorType) {
      setIsLoading(false);
      const errorMessage = ErrorHandler(res)
      setError(errorMessage)

  } else {
    const results = await res.data;
      setStatements(results)   
      setIsLoading(false);
      setError(null)
      console.log(statements)


  }
 
}


useEffect(()=>{
  getStatements();

},[])
    return(
        <div className='container text-center' style={{marginLeft:'30%'}}>
          <h3 style={{marginTop:'30px', marginBottom:'30px', textAlign:'left', color:'black'}}>Our Doctrines</h3>
           {
             statements.length===0 ? <p>No doctrines right now, check back later</p>
             :
             statements.map((st,index)=>{
               return <div key={st.id} style={{width:'60%',borderRadius:'8px', backgroundColor:'#eeefff', alignContent:'center', padding:'12px'}}>
               
                       <p>{index+1}. {st.statement}</p>
                    </div>
             })
           }

        </div>

    );
}

export default FaithStatements;