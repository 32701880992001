import axios from 'axios';
import { BASE_URL } from '../constants/paths';

 const baseUrl = BASE_URL //'http://localhost:5000/api/v1/';
 let errorObj ={
    errorMessage:null,
    statusCode:null,
    validationErrors:null,
    errorType:null
 }
   


export const makePostRequest = async(url,data)=>{
const end_point = baseUrl+url;
try{
    let res = await axios.post(end_point,data);
    
    return res;
   }catch(error){
      
    if(error.response){
        return responseError(error);
       

    }
    else {
       return requestError(error);

    }
    }
   
   }




export const makePutRequest = async(url,data)=>{
    const end_point = baseUrl+url;
    try{
        let res = await axios.put(end_point,data);
        return res;
       }catch(error){
       if(error.response){
        return responseError(error)
       }else{
        return requestError(error)
       }
       }
    
    
    }
    export const makeDeleteRequest = async(url)=>{
        const end_point = baseUrl+url;
        try{
            let res = await axios.delete(end_point);
            return res;
           }catch(error){
            if(error.response){
                return responseError(error)
               }else{
                return requestError(error)
               }
           }
        
        
        }
        //{retry:3,retryDelay:3000}

    export const makeGetRequest = async(url)=>{
        const end_point = baseUrl+url;

        console.log('BASE URL',end_point)
        try{
            let res = await axios.get(end_point);
           
            return res;
           }catch(error){
           
    if(error.response){
        return responseError(error)
       }else{
        return requestError(error)
       }
    
    
   
           }
        
        
        }

        export const makeMultipartFormPostRequest = async(url,data)=>{
            const end_point = baseUrl+url;
           
            try{
                let res = await axios.post(end_point,data,{
                    headers: {
                        "Content-Type": "multipart/form-data",
                      },
                });
                console.log(res)
                return res;
               }catch(error){
               
                if(error.response){
                    return responseError(error);                  
            
                }else{
                    return requestError(error);
                }
                             
               
               }
            
            
            }

            const responseError = (error)=>{
                 /**The request was made and the server responded with a status code
            that falls out of the range of 2xx
            console.log(error.response.data);
             console.log(error.response.status);
             console.log(error.response.headers);
            **/
           console.log('ERROR RESPONSE',error.response)
           if(error.response.status ===422){
                     
            errorObj.statusCode = error.response.status;
            errorObj.errorMessage = error.response.data;
            errorObj.errorType = "validation";
            errorObj.validationErrors = error.response.data.errors;
            return errorObj;
           }
           else if(error.response.status === 401){
           // EventBus.dispatch("logout");
            errorObj.statusCode = error.response.status;
            errorObj.errorMessage = error.response.data ||'please login';
            errorObj.errorType = "authentication";
            return errorObj;
           localStorage.removeItem('user')
            localStorage.removeItem('church')
            localStorage.removeItem('roles')
            sessionStorage.removeItem('token')
            return errorObj;
           
           }
           else if(error.response.status === 403){
           
            errorObj.statusCode = error.response.status;
            errorObj.errorMessage = error.response.data||'Unathorized Request!';
            errorObj.errorType = "authorization";
            return errorObj;
           }
           else if(error.response.status === 400){
            errorObj.statusCode = error.response.status;
            errorObj.errorMessage = error.response.data || 'Bad Request';
            errorObj.errorType = "badrequest";
            return errorObj;
           }
           else if(error.response.status === 429){
            errorObj.statusCode = error.response.status;
            errorObj.errorMessage = 'Request blocked, Try again after 30 minutes';
            errorObj.errorType = "Too Many Requests";
            return errorObj;
           }
          else{
           
            console.log('response error',error)
            errorObj.statusCode = error.response.status;
            errorObj.errorMessage = error.message;
            errorObj.errorType = "server";
            return errorObj;

           }

            }

            const requestError = (error)=>{
                 /** The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the 
            // browser and an instance of
            // http.ClientRequest in node.js
            **/
            errorObj.errorType ='request';
            errorObj.errorMessage = error.request;
            errorObj.statusCode = 500;
           console.log('request error',error)
           return errorObj;
            }