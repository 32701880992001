import React, { useState,useEffect } from 'react';
import { makeGetRequest } from '../services/httpHelper';
import ErrorHandler from '../services/ErrorHandler';
import { Container, Row, Col, Card } from 'react-bootstrap';
import LeadershipImage from '../assets/images/avatar.jpg';
import { toTitleCase } from '../utils/myFunctions';


const Leadership =()=>{
    const [leaders,setLeaders] =useState([])
    const [isLoading,setIsLoading] = useState(true)
    const [serverError,setServerError] = useState(null)
    const [showModal, setShowModal] = useState(false);
    const handleClose = () => setShowModal(false);
    const handleShow = () => setShowModal(true);

const user = JSON.parse(localStorage.getItem('user'))
const church = JSON.parse(localStorage.getItem('church'))


    const getChurchLeaders = async()=>{
        setIsLoading(true)
        const res = await makeGetRequest('list-church-leaders/'+church.id)
        if(res && res.errorType){
            setIsLoading(false)
            const errorMessage = ErrorHandler(res)
            setServerError(errorMessage)
            
        }else{
            setServerError(null)
            setLeaders(res.data);
            setIsLoading(false);
        }

    }
    useEffect(()=>{
        getChurchLeaders();
    },[])

    return(
        <div className='container'>
            {
                leaders.length===0 ?
                <p>No leadership data found</p>
                :
                <Row>
                    {
             leaders.map((leader)=>{
                const name = leader.leaderMembership.first_name+' '+leader.leaderMembership.last_name
                return   <Col lg={4} md={6} sm={12}>
                <LeaderCard
                  image={LeadershipImage}
                  name={toTitleCase(name)}
                  rank={leader.position.position.toUpperCase()}
                />
              </Col>

             })
                    }

                </Row>
              
            }

        </div>

    )
}

const LeaderCard = ({ image, name, rank }) => {
    return (
      <Card className="mb-4">
        <Card.Img variant="top" src={image} alt={name} />
        <Card.Body>
          <Card.Title>{name}</Card.Title>
          <Card.Text>{rank}</Card.Text>
        </Card.Body>
      </Card>
    );
  };


export default Leadership;