import React, { useState, useEffect,useRef } from 'react';
import ItemCard from './ItemCard';
import { BASE_URL, IMAGE_BASE_URL } from '../constants/paths';
import { makeGetRequest } from '../services/httpHelper';
import { CircularProgress } from '@mui/material';
import ErrorHandler from '../services/ErrorHandler';
import GetInvolvedDetail from './GetInvolvedDetail';
import { useNavigate } from 'react-router-dom';




const GetInvolved =()=>{

    const [opportunities,setOpportunities] = useState([]);
    const [error,setError] = useState(null);
    const [isLoading,setIsLoading] = useState(false)
    const [showModal, setShowModal] = useState(false);

    const church = JSON.parse(localStorage.getItem('church'));
    const navigate = useNavigate();


    useEffect(() => {
     
        async function getVolunteeringopportunities() {

            try{
                setIsLoading(true)
                // You can await here
                const res = await makeGetRequest('volunteer-opportunities/'+church.id);
                if (ResizeObserver && ResizeObserver.errorType) {
                    setIsLoading(false);
                    const errorMessage = ErrorHandler(res)
                    setError(errorMessage)
        
                } else {
                    setOpportunities(res.data) 
                    console.log('opportunities',opportunities.length)                 
                    setIsLoading(false);
                    

    
                }
            } catch (error) {
                console.error('Error fetching members:', error);
                // Handle errors
              }
          
    
    
           

        }
        
        getVolunteeringopportunities();

       


    }, [church.id]);

    const viewDetail =(opportunity)=>{
        navigate(`/get-involved`,{state:{opportunity:opportunity,opportunities}})

    }

    if(isLoading){
        <div className='text text-center'>
            <CircularProgress/>
        </div>
    }

    return(
        <>
        <div className='container'>

            <div className='row'>
            {
                opportunities.length===0 ?
                <p>No volunteering opportunities right now. Will be posted right when are available.</p>
                :
                opportunities.map((opp)=>{
                  return  <div className='col-md-4' key={opp.id} style={{cursor:'pointer'}} onClick={()=>viewDetail(opp)}>
                    <ItemCard title={opp.title} text={opp.detail} img={IMAGE_BASE_URL+opp.image}/>
                </div>

                })
            }

            </div>
        
            
           

        </div>
     

        
        </>
    )
}

export default GetInvolved;