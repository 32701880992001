import React, { useState,useEffect } from 'react';
import './VideoListing.css'; // Assume you have a CSS file for styling
import { makeGetRequest } from '../services/httpHelper';
import ErrorHandler from '../services/ErrorHandler';
import { toTitleCase } from '../utils/myFunctions';
import VideoCover from '../assets/images/videocover.jpg'
import { CircularProgress } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import { Helmet } from "react-helmet-async";





const VideoListing = () => {

    const [isLoading,setIsLoading] = useState(true)
    const [serverError,setServerError] = useState(null)
    const [videos,setVideos] = useState([])
    const [searchQuery, setSearchQuery] = useState('');




const church = JSON.parse(localStorage.getItem('church'))


    const getVideos = async()=>{
        setIsLoading(true)
        const res = await makeGetRequest('video-sermons/'+church.id)
        if(res && res.errorType){
            setIsLoading(false)
            const errorMessage = ErrorHandler(res)
            setServerError(errorMessage)
            
        }else{
            setServerError(null)
            setVideos(res.data);
            setIsLoading(false);
        }

    }
    useEffect(()=>{
        getVideos();
    },[])

     // Function to handle search query change
  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  // Function to filter videos based on search query
  const filteredVideos = videos.filter((video) => {
    const titleMatch = video.title.toLowerCase().includes(searchQuery.toLowerCase());
    const speakerMatch = video.speaker.toLowerCase().includes(searchQuery.toLowerCase());
    return titleMatch || speakerMatch;
  });

  const dataToPass = { name: 'John Doe', age: 25 }
 const navigate = useNavigate();

  function goToVideoPlayer(){
    console.log('called..')
    console.log(navigate)
     navigate(`/watch/${23433}`,{ replace: true }, {state:dataToPass})
     console.log('after navigation')
  }

  return (
   <>
<Helmet>
<title>Sermons</title>
<meta name="description" content='True gospel sermons at your disposal. Get blessed with video, text and audio videos for free all at one place.'/>
 <link rel='canonical' href='/donate'/>


</Helmet>
<div className='container'>
       <h2 style={{textAlign:'center', marginBottom:'30px'}}>Sermons</h2>

       <div className="search-bar">
        <input
          type="text"
          placeholder="Search by title or speaker"
          value={searchQuery}
          onChange={handleSearchChange}
        />
      </div>
      
            <br/>
            { isLoading  && <CircularProgress/>}
            {serverError !== null && <p className='text text-danger'>{serverError}</p>}
    {
        filteredVideos.length === 0 ?
        <p>No Sermons found</p>
        :
        <div className="video-listing">
         
      {filteredVideos.map((video, index) => (
        <div className="video-item" key={index}>
          <Link className='video-link' to={`/${church.website}/watch/${video.id}`}>

          <div className="thumbnail">
              <img src={VideoCover} alt={video.title} style={{ width: '100%', height: 'auto' }}/>
            </div>

          </Link>
        
          
          <div className="video-details">
            <h2 className='video-title'>{video.title}</h2>
            <p className='author'> &nbsp; By {video.speaker}</p>
            <p className='video-desc'>{video.description}</p>
          </div>
        </div>
      ))}
    </div>
    }
   </div>
   </>
    
  );
};

export default VideoListing;
