import React,{useState,useEffect} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import {makeDeleteRequest, makeGetRequest} from '../services/httpHelper'
import ErrorHandler from '../services/ErrorHandler';
import { toTitleCase } from '../utils/myFunctions';



const WeeklyProgram = ()=>{
    const [programs,setPrograms] =useState(null)
    const [processing,setIsProcessing] =useState(false)
    const [isLoading,setIsLoading] = useState(true)
    const [serverError,setServerError] = useState(null)   
 



const church = JSON.parse(localStorage.getItem('church'))
const navigate = useNavigate();


    const getPrograms = async()=>{
        setIsLoading(true)
        const res = await makeGetRequest('church-weekly-programs/'+church.id)
        if(res && res.errorType){
            setIsLoading(false)
            const errorMessage = ErrorHandler(res)
            setServerError(errorMessage)
            
        }else{
            setServerError(null)
            setPrograms(res.data);
            setIsLoading(false);
        }

    }

    const removeProgram = async(id,e)=>{
        e.preventDefault();
        setServerError(null)
             
      
        setIsProcessing(true)
        const del = await makeDeleteRequest('church-weekly-program/'+id)
        if (del && del.errorType) {
            setIsProcessing(false);
            const errorMessage = ErrorHandler(del)
            setServerError(errorMessage)

        } else {
        const filteredPrograms =   programs.filter((program)=>program.id !== id);
        setPrograms(filteredPrograms)
            setIsProcessing(false);
            navigate('/church-weekly-program')


        }

    } 
    useEffect(()=>{
        getPrograms();
    },[])

    const pageTitle ='Add Program';



 
    return(
        <div className="container">
            <div>
            <div className='row'>
                    <div className='col-md-2'>
                   
                    </div>
                    <div className='col-md-6'>
                    <h3> Weekly Program</h3>
                    
                    </div>
                
                 
                </div>

                {serverError !== null && alert(serverError)}
            {
                programs !== null ?
                <table className="table table-responsive table-striped">
                    <thead>
                        <tr>
                            <th>Day</th>
                            <th>Program</th>
                            <th>Time</th>
                        

                        </tr>
                        
                    </thead>
                    <tbody>
                            {
                                programs.map((program)=>{
                                    return <tr key={program.id}>
                                        <td>{toTitleCase(program.day)}</td>
                                        <td>{toTitleCase(program.program)}</td>
                                        <td>{program.start_time}-{program.end_time}</td>
                                        
                                        
                                   
                                    </tr>
                                })

                            }
                        </tbody>

                </table>
                :
                <div className="container">
                    <p className='text text-info'>No Church order of service at this time. Check back later</p>
                  
                </div>
            }
            </div>
       

                 

        </div>
    )
}

export default WeeklyProgram;
