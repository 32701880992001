import React, { useEffect, useState } from 'react';
import { makeGetRequest } from '../services/httpHelper';
import { Link } from 'react-router-dom';
import './Events.css';
import { Button } from 'react-bootstrap';
import { BASE_URL, IMAGE_BASE_URL } from '../constants/paths';
import CustomModal from '../components/CustomModal';
import EventDetail from './EventDetail';


const EventListing = () => {
  const [events, setEvents] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showDetailModal, setShowDetailModal] = useState(false);
  const [event, setEvent] = useState(null);


  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);

  const handleCloseDetail = () => setShowDetailModal(false);
  const handleShowDetailModal = (d) => {
    setEvent(d)
    setShowDetailModal(true);
  }

  const church = JSON.parse(localStorage.getItem('church'));

  useEffect(() => {
    // Fetch events from the backend when the component mounts
    async function fetchEvents() {
      try {
        const response = await makeGetRequest(`/church-events/${church.id}`); // Adjust the API 
        setEvents(response.data);
      } catch (error) {
        console.error('Error fetching events:', error);
      }
    }

    fetchEvents();
  }, []);

  const detailModalBody = <EventDetail pageTitle={event !== null && event.event_title} event={event}/>

  return (
    <>
     

<CustomModal 
            show={showDetailModal}
            showModal={showDetailModal}
            handleClose={handleCloseDetail}
            header='Event Detail'
            body={detailModalBody}
            size="lg" // You can change the size as needed
            />
   




<div className="container py-5">
<h2>Upcoming Events</h2>

<div className="row row-cols-1 row-cols-md-3 g-4">
{events.length !== 0 ? 
  events.map((event)=>{
    return <div className="col" key={event.id}>
    <div className="card h-100" style={{position: "relative"}} key={event.id}>
      <div className="bottom-right">{event.start_date}</div>
      <div className="top-right">
        <div className="tags-list mb-2">
          <span className="tags-list-town text-uppercase">{event.location.toUpperCase()}</span>
          <span style={{backgroundColor: "#28a745", textTransform: "uppercase", fontWeight: "800px"}}>
            {event.fee==0 ? 'FREE': event.fee}
          </span>
        </div>
      </div>
      <div className="image-container" onClick={()=>handleShowDetailModal(event)}>
        <div className="bottom-left">
          <div className="thumbnail" style={{backgroundColor: "#008080"}}>
            <div className="thumbnail-date">
              <span className="thumbnail-date-day">{new Date(event.start_date).getDay()}</span>
              <span className="thumbnail-date-month">{new Date(event.start_date).getMonth()}</span>
            </div>
          </div>
        </div>
        <Link >
        <img crossorigin="anonymous" src={IMAGE_BASE_URL+event.attachment}  className="card-img-top" alt="event photo" onClick={handleShow}/>
              
          </Link>
        <div className="image-overlay text-over text-center d-flex justify-content-center align-items-center text-uppercase">
          <div>
            {event.event_title}
          </div>
        </div>
     
      </div>
    </div>
  </div>
  })
  :
  <p>No events available</p>

}

</div>




  




</div>


    </>
    
  );
};

export default EventListing;
