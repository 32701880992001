import React, { useEffect,useState } from 'react';
import { Link,NavLink, useParams } from 'react-router-dom';
import './NavBar.css'
import {IMAGE_BASE_URL} from '../constants/paths'
import Logo from '../assets/logo.png'

const Navbar = () => {
  const [activeLink, setActiveLink] = useState('');
  const [active, setActive] = useState('home');

  const church = JSON.parse(localStorage.getItem('church'))
  const { token } = useParams();



  const scrollToSection = (id) => {
    const element = document.getElementById(id);
    if (element) {
      setActive(id)
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      const sections = document.querySelectorAll('section[id]');
      const scrollPosition = window.scrollY + 100;

      sections.forEach(section => {
        const sectionTop = section.offsetTop;
        const sectionHeight = section.offsetHeight;
        if (scrollPosition >= sectionTop && scrollPosition < sectionTop + sectionHeight) {
          setActiveLink(section.getAttribute('id'));
        }
      });
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <nav className="navbar">
        <div className="navbar-container container">
            <input type="checkbox" name="" id=""/>
            <div className="hamburger-lines">
                <span className="line line1"></span>
                <span className="line line2"></span>
                <span className="line line3"></span>
              
            </div>
            <ul className="menu-items">
                <li><Link to={`/${church && church.website}`} onClick={() => scrollToSection('home')}  className={active=== `/${church && church.website}` && 'nav-link-active'}>Home</Link></li>
                <li><Link to={`/${church && church.website}/about`}>About</Link></li>
                <li><Link to={`/${church && church.website}/events`}>Events</Link></li>
                <li><Link to={`/${church && church.website}/community-engagements`}>Missions</Link></li> 
                <li><Link to={`/${church && church.website}/sermons`}>Sermons</Link></li>
                <li style={{cursor:'pointer'}}><span onClick={() => scrollToSection('contact')}  className={active==='contact' && 'nav-link-active'}>Contact</span></li>
                {
                church && church.subscription_type.toLowerCase()==='premium' &&  <li><Link to={`/${church && church.website}/donate-with-paypal`}  className='btn' style={{marginLeft:"100px"}}>Give</Link></li>
               }
            </ul>
                      
    
            <img crossOrigin="anonymous" src={church && church.logo ? IMAGE_BASE_URL+church.logo :Logo } alt='LOGO' style={{marginTop:'0px', marginBottom:'0px', width:'150px', height:'60px', alignItems:'left'}}/>
           
            
        </div>
    </nav>
  );
};

export default Navbar;
