import React, { useState } from 'react';
import './Donate.css'; // Import CSS file for styling
import { Helmet } from "react-helmet-async";

const Donate = () => {
  // Define state variables for form data
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [dType, setDType] = useState('One-Time');
  const [amount, setAmount] = useState('');
  const [receipt, setReceipt] = useState(false);
  const [anon, setAnon] = useState(false);
  const [list, setList] = useState(false);

  const church = JSON.parse(localStorage.getItem('church'))

  // Handle change in input fields
  const handleInputChange = (e, setter) => {
    setter(e.target.value);
  };

  // Handle change in radio buttons
  const handleRadioChange = (value) => {
    setDType(value);
    alert(dType)
  };

  // Handle change in checkbox inputs
  const handleCheckboxChange = (e, setter) => {
    setter(e.target.checked);
  };

  // Handle click on donation amount buttons
  const handleAmountClick = (value) => {
    setAmount(value);
  };

  const metaDesc = `Donate to support ${church.church_name} programs`

  return (
    <>
<Helmet>
<title>Donate</title>
<meta name="description" content={metaDesc}/>
 <link rel='canonical' href='/donate'/>


</Helmet>
<div className="donation-container">
      <div className="donation-box">
        <div className="title">Donation Information</div>
        <div className="fields">
          <input
            type="text"
            id="firstName"
            placeholder="First Name"
            value={firstName}
            onChange={(e) => handleInputChange(e, setFirstName)}
          />
          <input
            type="text"
            id="lastName"
            placeholder="Last Name"
            value={lastName}
            onChange={(e) => handleInputChange(e, setLastName)}
          />
          <input
            type="text"
            id="email"
            placeholder="Email"
            value={email}
            onChange={(e) => handleInputChange(e, setEmail)}
          />
        </div>
        <div className="amount">
          <div className={`button ${amount === '$30' && 'selected'}`} onClick={() => handleAmountClick('$30')}>$30</div>
          <div className={`button ${amount === '$50' && 'selected'}`} onClick={() => handleAmountClick('$50')}>$50</div>
          <div className={`button ${amount === '$100' && 'selected'}`} onClick={() => handleAmountClick('$100')}>$100</div>
          <div className="button">$<input
            type="text"
            className="set-amount"
            placeholder=""
            value={amount}
            onChange={(e) => handleInputChange(e, setAmount)}
          /></div>
        </div>
        <div className="switch">
          <input
            type="radio"
            className="switch-input"
            name="view"
            value="One-Time"
            id="one-time"
            checked={dType === 'One-Time'}
            onChange={() => handleRadioChange('One-Time')}
          />
          <label htmlFor="one-time" className="switch-label switch-label-off">One-Time</label>
          <input
            type="radio"
            className="switch-input"
            name="view"
            value="Monthly"
            id="monthly"
            checked={dType === 'Monthly'}
            onChange={() => handleRadioChange('Monthly')}
          />
          <label htmlFor="monthly" className="switch-label switch-label-on">Monthly</label>
          <span className="switch-selection"></span>
        </div>
        <div className="checkboxes">
          <input
            type="checkbox"
            id="receipt"
            className="checkbox"
            checked={receipt}
            onChange={(e) => handleCheckboxChange(e, setReceipt)}
          />
          <label htmlFor="receipt">Email Me A Receipt</label>
          <br />
          <input
            type="checkbox"
            id="anon"
            className="checkbox"
            checked={anon}
            onChange={(e) => handleCheckboxChange(e, setAnon)}
          />
          <label htmlFor="anon">Give Anonymously</label>
          <br />
          <input
            type="checkbox"
            id="list"
            className="checkbox"
            checked={list}
            onChange={(e) => handleCheckboxChange(e, setList)}
          />
          <label htmlFor="list">Add Me To Email List</label>
        </div>
        <div className="confirm">
          {/* Confirmation message can be displayed here */}
        </div>
        <div className="donate-button"><i className="fa fa-credit-card"></i> Donate Now</div>
      </div>

      
    </div>
    
    </>
  );
};

export default Donate;
