import React,{useState,useEffect} from 'react';
import { CircularProgress } from '@mui/material';
import { makeGetRequest } from '../services/httpHelper';
import ErrorHandler from '../services/ErrorHandler';
import { Helmet } from "react-helmet-async";




const ChuchGroups = ()=>{
    const [groups,setGroups] =useState(null)
    const [isLoading,setIsLoading] = useState(true)
    const [serverError,setServerError] = useState(null)   

const church = JSON.parse(localStorage.getItem('church'))



    const getGroups = async()=>{
        setIsLoading(true)
        const res = await makeGetRequest('church-groups/'+church.id)
        if(res && res.errorType){
            setIsLoading(false)
            const errorMessage = ErrorHandler(res)
            setServerError(errorMessage)
            
        }else{
            setServerError(null)
            setGroups(res.data);
            setIsLoading(false);
        }

    }
    useEffect(()=>{
        getGroups();
    },[])
  


    if(isLoading && serverError ===null){
        return <CircularProgress/>
    }
    if(serverError){
        return <p>{serverError}</p>
    }

    const metaDesc = `${church.church_name} church groups`
    return(
        <>
        <Helmet>
  <title>Church Groups</title>
  <meta name="description" content={metaDesc}/>
   <link rel='canonical' href='/groups'/>


</Helmet>
        <div className="container" style={{marginTop:'40px'}}>
            <h1>Church Groups</h1>
          
            {serverError !== null && alert(serverError)}
            {
                groups !== null ?
                <table className="table table-responsive table-striped">
                    <thead>
                        <tr>
                            <th>Group Name</th>
                            <th>Description</th>
                            <th>Joining Fee</th>
                            <th>Participants</th>

                        </tr>
                        
                    </thead>
                    <tbody>
                            {
                                groups.map((group)=>{
                                    return <tr key={group.id}>
                                        <td>{group.group_name}</td>
                                        <td>{group.description}</td>
                                        <td>{group.reg_fee_amount ===0 ? "None" : group.reg_fee_amount}</td>
                                        <td >({group.groupMembers.length })</td>
                                    </tr>
                                })

                            }
                        </tbody>

                </table>
                :
                <div className="container">
                    <p>No groups found</p>
                   
                </div>
            }



        </div>
        
        </>
    )
}

export default ChuchGroups;
