import React,{useState} from 'react';
import './ContactForm.css'
import { Link, useNavigate } from 'react-router-dom';
import { isEmail,isEmpty,isMobile } from '../utils/formValidator';
import { makePostRequest } from '../services/httpHelper';
import ErrorHandler from '../services/ErrorHandler';
import { CircularProgress } from '@mui/material';
import { Helmet } from "react-helmet-async";


var validationErrors ={};


const ContactForm = () => {

  const church = JSON.parse(localStorage.getItem('church'))

  const [errors,setErrors] = useState({});
  const [serverError,setServerError] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [submitted, setSubmitted] = useState(false)

  const [values, setValues] = useState({
    name:'',
    email:'',
    message:'',
    subject:'',
    phone:''
    
  
  });

  const navigate = useNavigate();

  const handleChange = (e) =>{
    setValues({
        ...values,
       
        [e.target.name]:e.target.value
    })
}

  const sendMessage =async(e)=>{
     e.preventDefault();
     setServerError(null)
     setIsSubmitting(true)
     setErrors({})
     if(isEmpty(values.name)){
       validationErrors.name ='Enter your full name'
     }
         
      if(values.name.length < 5){
       validationErrors.name ='Name is too short'
       
     }
      if(isEmpty(values.email)){
       validationErrors.email='Enter your email address'
       
     }
      if(!isEmail(values.email)){
         validationErrors.email='Invalid Email Address'
     }
   
     if(isEmpty(values.subject)){
    validationErrors.subject='Please enter subject';
   }
   
   
  
   
      if(isEmpty(values.message)){
       validationErrors.message='Enter your message'
       
     }
      if(values.message.length < 10){
       validationErrors.message='Message is too short'
     }
      setErrors(validationErrors)
      //prevent data processing until all verification errors are fixed
   
      if(Object.keys(errors).length !==0){
       setIsSubmitting(false)
         return;
      }
        
      //   const captchaToken = await recaptchaRef.current.getValue();
   //recaptchaRef.current.reset();
   const data ={
     contactName:values.name,
     email:values.email,
     phone:values.phone,
     message:values.message,
     subject:values.subject,
     churchEmail:church.email
     //captchaToken:captchaToken
   }
         const res = await makePostRequest('contact-us',data);
         if (res && res.errorType) {
             setIsSubmitting(false);
             const errorMessage = ErrorHandler(res)
             setServerError(errorMessage)
   
         } else {
            
           
             setIsSubmitting(false);
             setServerError(null);
             setSubmitted(true)
   
         }
     
   }

  return (
    <>

<Helmet>
  <meta name="description" content="Please complete the form and send. We will be happy hearing from you and we will get back to you as soon as possible."/>
   <link rel='canonical' href='/contact-us'/>


</Helmet>

<section id="contact">
  <div className="contact-box">
    <div className="contact-links">
      <h2>CONTACT {church.church_name.toUpperCase()}</h2>
      <p>Thank you for choosing to reach out to us. Please complete the form to send message to us. We will be happy hearing from you and we will get back to you as soon as possible.</p>
      <div className="links">
       {
        /*
         <div className="link">
         <Link to=""><img src="https://i.postimg.cc/m2mg2Hjm/linkedin.png" alt="linkedin" className='contact-img'/></Link>
        </div>
        <div className="link">
         <Link to=""><img src="https://i.postimg.cc/YCV2QBJg/github.png" alt="github" className='contact-img'/></Link>
        </div>
        <div className="link">
         <Link to=""><img src="https://i.postimg.cc/W4Znvrry/codepen.png" alt="codepen" className='contact-img'/></Link>
        </div>
        <div className="link">
         <Link to=""><img src="https://i.postimg.cc/NjLfyjPB/email.png" alt="email" className='contact-img'/></Link>
        </div>

        */
       }
      </div>
    </div>
    <div className="contact-form-wrapper">
      {isSubmitting && <CircularProgress/>}
      {serverError !== null && <p className='text text-danger'>{serverError}</p>}
      {submitted && navigate(`/contact-us-success/${values.name}`)}

      <form onSubmit={sendMessage}>
        <div className="form-item">
        <br/>
          <input type="text" name="name" value={values.name} onChange={handleChange} required/>
          <label>Name:</label>
          {errors.name && <p className='text text-danger'>{errors.name}</p>}
        </div>
        <br/>
        <div className="form-item">
      
          <input type="email" name="email" value={values.email} onChange={handleChange} required/>
          <label>Email:</label>
          {errors.email && <p className='text text-danger'>{errors.email}</p>}
        </div>
        <br/>

        <div className="form-item">
      
      <input type="tel" name="phone" value={values.phone} onChange={handleChange} required/>
      <label>Phone Number:</label>
      {errors.phone && <p className='text text-danger'>{errors.phone}</p>}
    </div>
    <br/>
       
        <div className="form-item">
          <input type="text" name="subject" value={values.subject} onChange={handleChange} placeholder='subject' required />
          <label>Subject:</label>
          {errors.subject && <p className='text text-danger'>{errors.subject}</p>}
        </div>
        <br/>
        <div className="form-item">
          <textarea className="" name="message" value={values.message} onChange={handleChange} required rows={10}></textarea>
          <label>Message:</label>
          {errors.message && <p className='text text-danger'>{errors.message}</p>}
        </div>
        <br/>
        <button type='submit' className="submit-btn">{isSubmitting ? <CircularProgress/>:'Send'}</button>  
      </form>
    </div>
  </div>
</section>
    
    
 
</>
  );
}


export default ContactForm;
