import React from 'react';
import './Hero.css'
import CustomCard from './ItemCard';
import ActionSection from './ActionSection';
import {IMAGE_BASE_URL} from '../constants/paths'
import { Link } from 'react-router-dom';
import { Helmet } from "react-helmet-async";



const Hero = () => {
  const church = JSON.parse(localStorage.getItem('church'));
  const backgroundImage = church && church.hero_image
    ? `linear-gradient(rgba(34, 34, 34, 0.6), rgba(34, 34, 34, 0.6)), url("${IMAGE_BASE_URL + church.hero_image}")`
    : `linear-gradient(rgba(34, 34, 34, 0.6), rgba(34, 34, 34, 0.6)), url("https://images.unsplash.com/photo-1533777857889-4be7c70b33f7?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80")`;


  return (
   <>
       <Helmet>
  <title>{church.church_name}</title>
  <meta name="description" content={church.hero_text}/>
   <link rel='canonical' href='/'/>


</Helmet>
    <section>
      <header   style={{
        height: '100vh',
        position: 'relative',
        backgroundImage,
        backgroundSize: 'cover',
        color: '#fff'
      }}>


  <div className="header-container">
    <div className="header-container-inner">
      <h1>{church.hero_text ? church.hero_text :'Church is more than a place to attend, it is a place to call home'}</h1>
      <p>
    {church.hero_tag_line ? church.hero_tag_line : '  We exist to empower disciples, lead by example and change communities for the better'}
      </p>
     <div style={{marginLeft:"50%"}}>
    
      <Link to={`/${church.website}/donate`}className="btn" style={{margin:"20px"}}> Donate</Link>
     </div>
    </div>
  </div>
</header>


    </section>
   
   </>
  );
};

export default Hero;
