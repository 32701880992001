import React, { useEffect,useState } from 'react';
import { makeGetRequest } from '../services/httpHelper';
import ErrorHandler from '../services/ErrorHandler';





const PrivacyPolicy = () => {
  const church = JSON.parse(localStorage.getItem('church'));
  const [policy, setPolicy] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const getPolicy = async()=>{
    setIsLoading(true);
    const res = await makeGetRequest(`policy/terms`);
    if (res && res.errorType) {
        setIsLoading(false);
        const errorMessage = ErrorHandler(res)
        setError(errorMessage)

    } else {
        setPolicy(res.data)        
        setIsLoading(false);
        setError(null)


    }
   
}


  useEffect(()=>{
    getPolicy()

  },[])
  return (
   <div className='container'>
  {
    policy=== null ?
    <p>No Policy</p>
    :
    <div>
          <h2>Terms of Service</h2>
    <h5>Effect Date: {policy.date}</h5>
    <hr/>
    <div dangerouslySetInnerHTML={{ __html: policy.description }} />
        </div>
  }

    
    

   </div>
  );
};

export default PrivacyPolicy;
