import React, { useState, useEffect,useRef } from 'react';
import ItemCard from './ItemCard';
import { toTitleCase } from '../utils/myFunctions';
import { BASE_URL, IMAGE_BASE_URL } from '../constants/paths';
import { Link, Navigate, useLocation, useNavigate } from 'react-router-dom';




const GetInvolvedDetail =()=>{
    const {state} = useLocation();
   const navigate = useNavigate();


    const[relatedOpportunities,setRelatedOpportunities]=useState(null);
    const[currentOpportunity,setCurrentOpportunity]=useState(state.opportunity)
    const church = JSON.parse(localStorage.getItem('church'))


  

const filterOpp=(opp)=>{
    const opps = state.opportunities.filter((opp)=>opp.id !== state.opportunity.id);
    setRelatedOpportunities(opps);
    setCurrentOpportunity(opp)

}

const getInvolved =()=>{
    navigate('/contact-us')
}

    
useEffect(()=>{
    filterOpp(state.opportunity)
},[currentOpportunity])


    return(
        <>
        <div className='container'>
            <br/>
            <section>
            <div className='row'>
                <div className='col-md-4'>
                    <h2 style={{margin:'15px'}}>{currentOpportunity.title}</h2>
                    <br/>
                <img crossOrigin="anonymous"  src={IMAGE_BASE_URL+currentOpportunity.image} style={{maxWidth:'400px', height:'300px', marginLeft:'25px', marginRight:'25px', marginTop:'10px'}}/>
                <br/><br/>
                <Link to={`/${church.website}/contact-us`} className='cta-btn'>Contact Us</Link>

                </div>

                <div className='col-md-7'>
                   <p style={{margin:'15px', alignItems:'center', textAlign:'center'}}>{currentOpportunity.detail}</p> 
                <div style={{alignItems:'center', margin:'50px'}}>
                <h5>Required Skills</h5>
                   {
                    currentOpportunity.skills.length===0 ?
                    <p>No specific skills required</p>
                    :
                   <ol>
                    {
                         currentOpportunity.skills.map((skill)=>{
                            return <li key={skill.id}>{toTitleCase(skill.skill)}</li>
                        })
                    }
                   </ol>
                   }
<br/>
                   
                    <p>Location : {currentOpportunity.location}</p>
                    <p>Opportunity available from: {currentOpportunity.start_date}</p>
                    <button type='button' className='cta-btn' onlick={()=>getInvolved()}>Get Involved</button>
                   
                </div>
                    
                    </div>
         

            </div>

            </section>

           

            <section style={{marginTop:'100px', backgroundColor:'transparent'}}>
                <div className='row'>
                    {
                        relatedOpportunities !==null && 
                        relatedOpportunities.map((opp)=>{
                            return  <div className='col-md-4' key={opp.id} style={{cursor:'pointer'}} onClick={()=>filterOpp(opp)}>
                            <ItemCard title={opp.title} text={opp.detail} img={IMAGE_BASE_URL+opp.image}/>
                        </div>
                        })
                    }
                </div>
            </section>
        
            
           

        </div>
     

        
        </>
    )
}

export default GetInvolvedDetail;