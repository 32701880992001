import React, { useEffect, useState } from 'react';
import './Testimonials.css'
import { makeGetRequest } from '../services/httpHelper';
import ErrorHandler from '../services/ErrorHandler';
import { IMAGE_BASE_URL } from '../constants/paths';
import { Helmet } from "react-helmet-async";


var testimonials = [];


const Testimonials = () => {
  const [idx, setIdx] = useState(0);

  const [testimonies, setTestimonies] = useState(testimonials);
  const [isLoading,setIsLoading] = useState(false);
  const [error,setError] = useState(null);
  const church = JSON.parse(localStorage.getItem('church'));


  const getTestimonies = async()=>{
    setIsLoading(true);
    const res = await makeGetRequest(`testimonials/${church.id}`);
    if (res && res.errorType) {
        setIsLoading(false);
        const errorMessage = ErrorHandler(res)
        setError(errorMessage)

    } else {
      const result = await res.data
      console.log('result',result.le)
      result.map((testimony)=>{
        if(testimonials.length < result.length){
          testimonials.push(testimony)
        }
      
      
       
      })
        setTestimonies(testimonials)        
        setIsLoading(false);
        setError(null)


    }
   
}

  useEffect(() => {
    getTestimonies()
    const interval = setInterval(() => {
      setIdx(prevIdx => (prevIdx + 1) % testimonies.length);
    }, 10000);

    return () => clearInterval(interval);
  }, []);

  const metaDesc = `${church.church_name} testimonials`


  return (
    <>
<title>Testimonials</title>
<Helmet>
<meta name="description" content={metaDesc}/>
 <link rel='canonical' href='/donate'/>


</Helmet>
    {
        testimonies.length !== 0 &&
        <div className='container'>
       
      
        <h1>Testimonials</h1>
        <section id="weekly-program">
      <div className="testimonials-container">
      <div className="progress-bar"></div>
  <div className="fas fa-quote-right fa-quote"></div>
  <div className="fas fa-quote-left fa-quote"></div>
        <div className="testimonial">
          {testimonies[idx].testimony}
        </div>
        <div className="user-info">
          <img className="user-image rounded-circle" src={IMAGE_BASE_URL+testimonies[idx].testifier_photo} alt="User" />
          <div>
            <p className="username">{testimonies[idx].testifier_name}</p>
          </div>
        </div>
      </div>
    </section>
    </div>


    }
    </>
  );
};

export default Testimonials;
