import React, { useState, useEffect } from 'react';
import { CircularProgress } from '@mui/material';
import { makeGetRequest,  } from '../services/httpHelper';
import { Link, } from 'react-router-dom';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ErrorHandler from '../services/ErrorHandler';


const Announcements = () => {

    const [announcemnts, setAnnouncements] = useState();
    const [isLoading, setIsLoading] = useState(false)
    const [isSuccess, setIsSuccess] = useState(false);
    const [error, setError] = useState(null);
          
    
    useEffect(() => {
     
        async function fetchAllAnnouncements() {
            const church = JSON.parse(localStorage.getItem('church'));
            setIsLoading(true)
            // You can await here
            const res = await makeGetRequest(`announcements/${church.id}`);
            if (res && res.errorType) {
                setIsLoading(false);
                const errorMessage = ErrorHandler(res)
                setError(errorMessage)
    
            } else {
                setAnnouncements(res.data)
                setIsLoading(false);
                setIsSuccess(true)
    
            } 

        }
        
        fetchAllAnnouncements();      
       


    }, []);
    return (
        <>
                        
                    <div className="">

                        {
                            isSuccess ?
                                <div>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div>
                                                {
                                                    announcemnts === undefined ?
                                                    <p className='text text-info'>No announcements</p>
                                                    :
                                                    announcemnts.map((announcement) => {
                                                        return (
                                                            <Accordion key={announcement.id}>
                                                                <AccordionSummary
                                                                    expandIcon={<ExpandMoreIcon />}
                                                                    aria-controls="panel1a-content"
                                                                    id="panel1a-header"
                                                                >
                                                                    <Typography>{announcement.title.toUpperCase()}</Typography>
                                                                </AccordionSummary>
                                                                <AccordionDetails>
                                                                    <Typography>
                                                                        <div className='row'>
                                                                            <div className="col-md-8">
                                                                                {announcement.content}
                                                                            </div>
                                                                           

                                                                        </div>


                                                                    </Typography>
                                                                </AccordionDetails>
                                                            </Accordion>
                                                        )
                                                    })

                                                }
                                                   
                                                



                                            </div>

                                        </div>

                                        
                                    </div>

                                </div>
                                :
                                <div>
                                    {isLoading && error === null && <CircularProgress />}
                                    {error && <p className="text text-danger">{error}</p>}



                                </div>
                        }


                    </div>                
                   
            


        </>


    );
}
export default Announcements;