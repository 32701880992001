import React from 'react';
import { Card } from 'react-bootstrap';
//import './ActionSection.css'

import { FaBeer, FaBible, FaNewspaper, FaPray, FaProcedures, FaRegUser, FaUserFriends } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import Announcements from './Announcements';
import { toTitleCase } from '../utils/myFunctions';
import WeeklyProgram from './WeeklyProgram';
import { Helmet } from "react-helmet-async";



const ActionSection = () => {

        const church = JSON.parse(localStorage.getItem('church'))
        const metaDesc = `${church.church_name} announcements, doctrines, our weekly schedule, up comming events, church groups`

  return (
  
  <>
     <Helmet>
  <title>Our Engagements</title>
  <meta name="description" content={metaDesc}/>
   <link rel='canonical' href='/'/>


</Helmet>
  <div className='container'>

        <div style={{display:'flex', paddingTop:'50px',marginBottom:'50px'}}>
        <h5 style={{marginRight:'100px', fontSize:'30px'}}>You are welcome at our church. We accept everybody and would love to have you.</h5>
                
                <img src="https://www.dropbox.com/s/kqljwe25hcx0zjn/michelle-jimenez-211321-unsplash.jpg?raw=1" alt="church person raising hands" id="image1" style={{width:'100%',height:'90%'}}/>

        </div>

        <div>
        <div style={{width:'100%',marginTop:'100px', marginRight:'50px'}}>
        <WeeklyProgram /> 

      
        <h2>Announcements</h2>

        <Announcements/>


         
        </div>
      

        </div>
  

  </div>

  <div className='container'>

        <div style={{marginTop:'30px'}}>
        <div className='row'>
        <div className='col-md-3'>
       <div className='text-center'>
        <FaRegUser className='card-icon' size={40}/>
        </div>
        <h5 className="card-title text-center">I am New here</h5>
        <p style={{fontSize:'14px', textAlign:'center'}}>We will love to have you. Need some help on how can engage or worship with us?</p>
        <div className='text-center'>
        <Link  to={`/${church.website}/donate-with-paypal`} className='btn btn-success text-center' style={{color:"white", fontStyle:'underline'}}>Contact Us</Link>
        </div>


                </div>

                <div className='col-md-3'>
       <div className='text-center'>
        <FaBible className='card-icon' size={40}/>
        </div>
        <h5 className="card-title text-center">Our Doctrines</h5>
        <p style={{fontSize:'14px', textAlign:'center'}}>Our doctrines or statement of faith are open. We believe in Jesus Christ as Lord and Savior.</p>
        <div className='text-center'>
        <Link to="/doctrines" className='btn btn-success text-center' style={{color:"white", fontStyle:'underline'}}>Learn More</Link>
        </div>


                </div>

                <div className='col-md-3'>
       <div className='text-center'>
        <FaRegUser className='card-icon' size={40}/>
        </div>
        <h5 className="card-title text-center">Our Church Groups</h5>
        <p style={{fontSize:'14px', textAlign:'center'}}>Our Church groups cut across ages, gender and talents. Praise and worship, youth groups, women fellowships...</p>
        <div className='text-center'>
        <Link to={`/${church.website}/groups`} className='btn btn-success text-center' style={{color:"white", fontStyle:'underline'}}>Explore</Link>
        </div>


                </div>
        </div>

        </div>

  
        </div>
  </>

   
    
    

  );
};

export default ActionSection;
