import React,{useState,useEffect} from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { makeGetRequest } from '../services/httpHelper';
import ErrorHandler from '../services/ErrorHandler';
import { FaRProject } from 'react-icons/fa';
import { IMAGE_BASE_URL } from '../constants/paths';
import { Helmet } from "react-helmet-async";


const CommunityEngagements = () => {

    const church = JSON.parse(localStorage.getItem('church'));
    const [engagements, setEngagements] = useState([]);
    const [missions, setMissiones] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
   
   
    const getMissions = async()=>{
      setIsLoading(true);
      const res = await makeGetRequest(`missions/${church.id}`);
      if (res && res.errorType) {
          setIsLoading(false);
          const errorMessage = ErrorHandler(res)
          setError(errorMessage)
  
      } else {
        const results = await res.data
        setMissiones(results)        
          setIsLoading(false);
          setError(null)
  
  
      }
     
  }

  const getCommunityEngagements = async()=>{
    setIsLoading(true);
    const res = await makeGetRequest(`community-engagements/${church.id}`);
    if (res && res.errorType) {
        setIsLoading(false);
        const errorMessage = ErrorHandler(res)
        setError(errorMessage)

    } else {
      const results = await res.data
      setEngagements(results)        
        setIsLoading(false);
        setError(null)


    }
   
}

  useEffect(()=>{
//getMissions();
getCommunityEngagements();
  },[])

  const metaDesc = `${church.church_name} community engagements`
 

  return (
   <>
       <Helmet>
  <title>Our Engagements</title>
  <meta name="description" content={metaDesc}/>
   <link rel='canonical' href='/community-engagements'/>


</Helmet>
    <Container>
       {/* <h2>Missions</h2>
      <Row>
        {missions.map((engagement, index) => (
          <Col key={index} xs={12} md={6} lg={4}>
            
            <Card className="mb-4 text-center">
            <FaRProject className='text-center'/>
              <Card.Body>
                <Card.Title>{engagement.title}</Card.Title>
                <Card.Text>{engagement.description}</Card.Text>
                <Card.Text>
                  <strong>Location:</strong> {engagement.location}
                </Card.Text>
                <Card.Text>
                  <strong>Date:</strong> {engagement.date}
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>*/}
      <h2>Community Engagements</h2>

      <Row>
        {engagements.map((engagement, index) => (
          <Col key={index} xs={12} md={6} lg={4}>
            
            <Card className="mb-4 text-center">
            <FaRProject className='text-center'/>
              <Card.Body>
                <Card.Title>{engagement.activity}</Card.Title>
                <img crossOrigin="anonymous"  src={IMAGE_BASE_URL+engagement.photo}/>
                <Card.Text>{engagement.description}</Card.Text>
              
                <Card.Text>
                  <strong>Date:</strong> {engagement.date}
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    </Container>
   
   </>
  );
};

export default CommunityEngagements;
