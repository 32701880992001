import React, { useState,useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { makeGetRequest } from '../services/httpHelper';
import ErrorHandler from '../services/ErrorHandler';
import { CircularProgress } from '@mui/material';
import VideoCover from '../assets/images/videocover.jpg'
import './VideoSermon.css'
import { toTitleCase } from '../utils/myFunctions';



const VideoPlayer =()=>{

    const {id}= useParams();

    const [isLoading,setIsLoading] = useState(true)
    const [serverError,setServerError] = useState(null)
    const [videos,setVideos] = useState([])
    const [currentVideo,setCurrentVideo] = useState(null)
    const [galleryVideos,setGallaryVideos] = useState([])
    const church = JSON.parse(localStorage.getItem('church'))

   
    const getVideos = async()=>{
        setIsLoading(true)
        const res = await makeGetRequest('video-sermons/'+church.id)
        if(res && res.errorType){
            setIsLoading(false)
            const errorMessage = ErrorHandler(res)
            setServerError(errorMessage)
            
        }else{

             //set current video
             const result = await res.data;
            
            setVideos(result);
                   

            setServerError(null)

            setIsLoading(false);
        }

    }

    const setShowingVideo =()=>{
        if(videos){
            const current = videos.filter((v)=>v.id===id);
            console.log('current',current)
            setCurrentVideo(current)
          const filtered = videos.filter((vs)=>vs.id !== id)
          setGallaryVideos(filtered)

          console.log('current video',currentVideo)

          console.log('gallery',galleryVideos)
        }
    }

    const handleGalleryItemClick =(video)=>{
        console.log('Video',video)
        setCurrentVideo(video)
       // const filtered = videos.filter((vs)=>vs.id !== video.id)
      //  setVideos(filtered);

      }

  

    useEffect(()=>{
        getVideos();
        setShowingVideo()

     
      

    },[])

    if (isLoading) {
        return <CircularProgress />;
      }
    
      if (serverError) {
        return <div>Error: {serverError}</div>;
      }
    
      if (!videos) {
        return null; // Render nothing until appIsReady is true
      }

    
  

    return(
        <div>
            <h2>{currentVideo.title}</h2>
            <div id="site">
      <div id="player">
        <div className="player">
          <div className={currentVideo.title}>
            <iframe title="current-video" width="560" height="315" src={currentVideo.body}  allow="autoplay; encrypted-media" allowFullScreen style={{border:'none'}}></iframe>
          </div>
        </div>
      </div>

      <p style={{fontSize:'18px', textAlign:'center'}}>{currentVideo.description}</p>
    
    </div>

       <div id="gallery">
        <div className="gallery" id='gallery'>
            {
                videos.map((video, index) => (
                    <div key={index} className="gallery__item" data={video.body} onClick={()=>handleGalleryItemClick(video)}>
                      <img className="gallery__item__img" src={VideoCover} alt={video.title} />
                      <span className="gallery__item__span">{toTitleCase(video.title)}</span>
                    </div>
            ))}
        </div>
      </div>
        </div>
        

    )
}

export default VideoPlayer;