import { useEffect, useState } from "react";
import './HorizontalVideoGallery.css'
import { makeGetRequest } from '../services/httpHelper';
import ErrorHandler from '../services/ErrorHandler';

const HorizontalVideoGallery = () => {

  const [videos, setVideos] = useState([]);
  const [isLoading,setIsLoading] = useState(false);
  const [error,setError] = useState(null);


  const church = JSON.parse(localStorage.getItem('church'))

  const getVideos = async()=>{
    setIsLoading(true);
    const res = await makeGetRequest(`video-galleries/${church.id}`);
    if (res && res.errorType) {
        setIsLoading(false);
        const errorMessage = ErrorHandler(res)
        setError(errorMessage)

    } else {
      const result = await res.data
      console.log(result)
        setVideos(result)        
        setIsLoading(false);
        setError(null)


    }
   
}
  

  useEffect(()=>{
    getVideos();

  },[])
  if(error){
    return <p className="text text-danger">Something wrong, could not load video gallery.</p>
  }

  return (
    <div id="container">
      
      <div className="horizontal-slider">
          {
            videos.length !== 0 ?
            videos.map((v,index)=>{
             return <div className="video-wrap" key={index}>
              <div className="video">
                <iframe src={`${v.video}amp;controls=1&amp;showinfo=0" frameBorder="0" allow="autoplay; encrypted-media`} allowFullScreen title="Video"></iframe>
              </div>
            </div>

            })
            :
            <p>Video Gallery is Empty</p>
          }
         
      
       {/* <div className="video-wrap">
          <div className="video">
            <iframe src="https://www.youtube.com/embed/f-YjPg8uXfk?rel=0&amp;controls=1&amp;showinfo=0" frameBorder="0" allow="autoplay; encrypted-media" allowFullScreen title="Video"></iframe>
          </div>
        </div>
        <div className="video-wrap">
          <div className="video">
            <iframe src="https://www.youtube.com/embed/vOe-P5WMQ08?rel=0&amp;controls=1&amp;showinfo=0" frameBorder="0" allow="autoplay; encrypted-media" allowFullScreen title="Video"></iframe>
          </div>
        </div>
        <div className="video-wrap">
          <div className="video">
            <iframe src="https://www.youtube.com/embed/pfXLrz-onGM?rel=0&amp;controls=0&amp;showinfo=0" frameBorder="0" allow="autoplay; encrypted-media" allowFullScreen title="Video"></iframe>
          </div>
        </div>
        <div className="video-wrap">
          <div className="video">
            <iframe width="560" height="315" src="https://www.youtube.com/embed/l4AI6T0-isc?rel=0&amp;controls=0&amp;showinfo=0" frameBorder="0" allow="autoplay; encrypted-media" allowFullScreen title="Video"></iframe>
          </div>
        </div>
        <div className="video-wrap">
          <div className="video">
            <iframe src="https://www.youtube.com/embed/X1rMtiSfpFU?rel=0&amp;controls=0&amp;showinfo=0" frameBorder="0" allow="autoplay; encrypted-media" allowFullScreen title="Video"></iframe>
          </div>
        </div>
        <div className="video-wrap">
          <div className="video">
            <iframe src="https://www.youtube.com/embed/4Jjh2XdpQYE?rel=0&amp;controls=0&amp;showinfo=0" frameBorder="0" allow="autoplay; encrypted-media" allowFullScreen title="Video"></iframe>
          </div>
        </div>*/}
      </div>
    </div>
  );
}

export default HorizontalVideoGallery;
