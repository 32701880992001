// ChurchWebsite.js

import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { CircularProgress } from '@mui/material';
import Home from './Home'; // Replace this with your church website component
import { makeGetRequest } from '../services/httpHelper';
import ErrorHandler from '../services/ErrorHandler';

const ChurchWebsite = ({defaultToken=null}) => {
  const { token } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [churchDetails, setChurchDetails] = useState(null);

  useEffect(() => {
    const fetchChurchDetails = async () => {
    
      try {
        const res = await makeGetRequest(`website/${token === undefined ? defaultToken : token}`);
        if (res.errorType) {
          setError(ErrorHandler(res));
        } else {
          setChurchDetails(res.data);
          const church = JSON.stringify(res.data)
          localStorage.setItem('church',church)
        }
      } catch (error) {
        setError(error.message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchChurchDetails();
  }, [token]);

  if (isLoading) {
    return <CircularProgress />;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (!churchDetails) {
    return <div>Church details not found</div>;
  }

  return <Home/>;
};

export default ChurchWebsite;
