import React, { useEffect } from 'react';
import {Routes, Route, useParams} from 'react-router-dom';
import Home from './pages/Home';
import Footer from './components/Footer';
import NavBar from './components/NavBar';
import About from './pages/About';
import Terms from './pages/Terms';
import PrivacyPolicy from './pages/PrivacyPolicy';


import 'bootstrap/dist/css/bootstrap.css';
import ContactForm from './components/ContactForm';
import Testimonials from './components/Testimonials';
import VideoListing from './components/VideoListing';
import Donate from './components/Donate';
import VideoPlayer from './components/VideoPlayer';
import NotFound from './http-response/NotFound';
import { DonateApp } from './components/PaypalDonate';
import EventListing  from './components/Events';
import Mission from './components/Mission';
import GetInvolved from './components/GetInvolved';
import GetInvolvedDetail from './components/GetInvolvedDetail';
import FaithStatements from './components/FaithStatements';
import Leadership from './components/Leadership';
import FAQs from './components/FAQs';
import CommunityEngagements from './components/CommunityEngagments';
import ChurchGroups from './components/ChurchGroups';
import MessageSendSuccess from './components/MessageSendSuccess';
import ChurchWebsite from './pages/ChurchWebsite';




const App = () => {


  return (
    

    <>
      <NavBar/> 
     
      <Routes>
        <Route exact path="/:token" element={<ChurchWebsite/>}/>
        <Route exact path="/:token/about" element={<About/>}/>
        <Route exact path="/:token/contact-us" element={<ContactForm/>}/>
        <Route exact path="/:token/contact-us-success/:name" element={<MessageSendSuccess/>}/>
        <Route exact path="/:token/events" element={<EventListing/>}/>
        <Route exact path="/:token/missions" element={<Mission/>}/>
        <Route exact path="/:token/get-involved" element={<GetInvolved/>}/>
        <Route exact path="/:token/get-involved-detail" element={<GetInvolvedDetail/>}/>
        <Route exact path="/:token/doctrines" element={<FaithStatements/>}/>
        <Route exact path="/:token/leadership" element={<Leadership/>}/>
        <Route exact path="/:token/faqs" element={<FAQs/>}/>
        <Route exact path="/:token/community-engagements" element={<CommunityEngagements/>}/>
        <Route exact path="/:token/groups" element={<ChurchGroups/>}/>
       <Route exact path="/:token/sermons" element={<VideoListing/>}/>
        <Route exact path="/:token/terms" element={<Terms/>}/>
        <Route exact path="/:token/privacy" element={<PrivacyPolicy/>}/>
        <Route exact path="/:token/testimonies" element={<Testimonials/>}/>
        <Route exact path="/:token/watch/:id" element={<VideoPlayer/>}/>
        <Route exact path="/:token/donate" element={<Donate/>}/>
        <Route exact path="/:token/video-player" element={<VideoPlayer/>}/>
        <Route exact path="/:token/donate-with-paypal" element={<DonateApp/>}/>
         {/* Fallback route */}
      <Route path="/*" element={<ChurchWebsite defaultToken="demo-website" />} />







      
      </Routes>
     <Footer />
    </>
  );
};

export default App;
